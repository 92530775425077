import React from 'react';
import * as S from './styles';
import { LoadingOutlined } from '@ant-design/icons';
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'small' | 'medium' | 'large' | 'none';
  iconOnly?: boolean;
  buttonType?: 'primary' | 'alternativePrimary' | 'secondary' | 'alternativeSecondary' | 'tertiary' | 'tertiarySecondary' | 'danger' | 'dangerSecondary';
  children?: React.ReactNode;
  iconRN?: React.ReactNode;
  active?: boolean;
  loading?: boolean;
  disable?: boolean;
  iconAfterText?: React.ReactNode;
}
export function ButtonUI({
  size = 'small',
  iconOnly = false,
  iconRN,
  buttonType = 'primary',
  active = false,
  loading = false,
  disable = false,
  children,
  iconAfterText,
  ...props
}: ButtonProps) {
  return <S.StyledButton $iconOnly={iconOnly} $size={size} $buttonType={buttonType} $active={active} $loading={loading} $disable={disable} {...props} data-sentry-element="unknown" data-sentry-component="ButtonUI" data-sentry-source-file="index.tsx">
      {!!iconRN && !loading && <S.Icon $iconOnly={iconOnly} className={`icon-btn ${iconOnly ? 'only-icon' : ''}`}>
          {iconRN}
        </S.Icon>}
      {loading ? <LoadingOutlined style={{
      fontSize: '13px'
    }} spin /> : children || <></>}
      {iconAfterText ? <S.iconAfterText className={`icon-btn `}>
          {iconAfterText}
        </S.iconAfterText> : ''}
    </S.StyledButton>;
}