'use client';

import { FloatButton } from 'antd';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import VLibras from 'vlibras-nextjs';
import { GlobalStyles } from '@/styles/global';
import { useAppAccessibility } from '@/context/accessibilityContext';
import { AuthContextProvider } from '@/context/authContext';
import { CookieBanner } from '@/components/CookieBanner';
import theme from '@/styles/theme';
import * as S from './styles';
import 'cegov-antd/dist/index.css';
const queryClient = new QueryClient();
export function RootLayoutCustom({
  children
}: {
  children: React.ReactNode;
}) {
  const {
    lengthText
  } = useAppAccessibility();
  return <>
      <AuthContextProvider data-sentry-element="AuthContextProvider" data-sentry-source-file="index.tsx">
        <S.VLibrasContainer data-sentry-element="unknown" data-sentry-source-file="index.tsx">
          <VLibras forceOnload data-sentry-element="VLibras" data-sentry-source-file="index.tsx" />
        </S.VLibrasContainer>
        <FloatButton.BackTop style={{
        top: '90%',
        zIndex: 10000
      }} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
        <ThemeProvider theme={theme} data-sentry-element="ThemeProvider" data-sentry-source-file="index.tsx">
          <GlobalStyles lengthText={lengthText} data-sentry-element="GlobalStyles" data-sentry-source-file="index.tsx" />
          <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="index.tsx">
            {children}
          </QueryClientProvider>

          <CookieBanner data-sentry-element="CookieBanner" data-sentry-source-file="index.tsx" />
        </ThemeProvider>
      </AuthContextProvider>
    </>;
}