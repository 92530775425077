'use client';

import { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import Cookies from 'js-cookie';
import { KEY_STORAGE_COORDENADORIA, KEY_CACHE_PROFILE_USER, KEY_STORAGE_FIRST_NAME, profilesServiceRoutes, TypeRolesPermisson, profilesPermisson, KEY_STORAGE_USER, KEY_CACHE_TOKEN } from '../../constants/user-profiles-enums';
import { isEmptyObject } from '@/utils/object';
import { addToStorage } from '@/services/StorageService';
import { apiPlataformaIndicadores } from '@/services/api';
import { TypeApiUserAcesso } from '@/@types/autenticacao';
import { findUserAcessoCoordenadoriaDivisao, isCadastroAtivo } from '@/services/UserService';
import { Modal } from 'antd';
import { debug } from 'console';
interface AuthContextProps {
  handleLogin: () => Promise<void>;
  handleLogout: () => void;
}
interface AuthContextProviderProps {
  children: React.ReactNode;
}
const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);
function AuthContextProvider({
  children
}: AuthContextProviderProps) {
  const {
    push
  } = useRouter();
  const handleLogin = useCallback(async () => {
    return push(`${process.env.NEXT_PUBLIC_LOGIN_WEB_APP}?app-key=${process.env.NEXT_PUBLIC_APP_KEY}`);
  }, [push]);
  const handleLogout = useCallback(() => {
    Cookies.remove(KEY_CACHE_TOKEN);
    Cookies.remove(KEY_CACHE_PROFILE_USER);
    localStorage.removeItem(KEY_STORAGE_USER);
    localStorage.removeItem(KEY_STORAGE_COORDENADORIA);
    localStorage.removeItem(KEY_STORAGE_FIRST_NAME);
  }, []);
  const valuesProvider = useMemo(() => {
    return {
      handleLogin,
      handleLogout
    };
  }, [handleLogin, handleLogout]);
  return <AuthContext.Provider value={valuesProvider} data-sentry-element="unknown" data-sentry-component="AuthContextProvider" data-sentry-source-file="index.tsx">
      {children}
    </AuthContext.Provider>;
}
function useAuthentication() {
  const context = useContext(AuthContext);
  if (isEmptyObject(context)) {
    throw new Error('useAuthentication must be used within a AuthContextProvider');
  }
  return context;
}
function useFetchAndVerifyAcessoCidadao() {
  const searchParams = useSearchParams();
  const {
    replace
  } = useRouter();
  const {
    handleLogout
  } = useAuthentication();
  useEffect(() => {
    const fetchAndVerifyApiAcessoCidadao = async () => {
      const tokenUrl = searchParams.get('token');
      const tokenCache = Cookies.get(KEY_CACHE_TOKEN);
      if (tokenCache) {
        const idProfile = Cookies.get(KEY_CACHE_PROFILE_USER);
        if (idProfile !== undefined) return replace(`/${profilesServiceRoutes[JSON.parse(idProfile)]}`);
      }
      Cookies.set(KEY_CACHE_TOKEN, JSON.stringify(tokenUrl));
      if (tokenUrl) {
        try {
          const response = await apiPlataformaIndicadores.post<TypeApiUserAcesso>(`acesso?token=${tokenUrl}`);
          const infoUserApi = response.data;
          if (!infoUserApi.usuario) {
            console.error('CPF/CNPJ indefinido no objeto user.');
            return replace('/error');
          }
          const cadastroAtivo = await isCadastroAtivo(infoUserApi.usuario.cpfCnpj);
          if (cadastroAtivo === true) {
            addToStorage(KEY_STORAGE_USER, JSON.stringify(response.data));
          } else {
            Modal.error({
              title: 'Atenção, seu acesso está desativado',
              content: 'Solicite suporte aos administradores do sistema'
            });
            return replace('/error');
          }
          const firstRole = infoUserApi.usuario.perfis[0];
          const numberRole = profilesPermisson[firstRole as TypeRolesPermisson];
          Cookies.set(KEY_CACHE_PROFILE_USER, JSON.stringify(numberRole));

          // Verificar coordenadoria e divisão
          const UserAcessoCoordenadoriaDivisao = findUserAcessoCoordenadoriaDivisao(infoUserApi.usuario.cpfCnpj);
          console.log('Requisição pai: ', UserAcessoCoordenadoriaDivisao);
          const coordenadoria = (await UserAcessoCoordenadoriaDivisao).coordenadorias;
          const divisao = (await UserAcessoCoordenadoriaDivisao).divisoes;
          console.log('Console da divisao: ', divisao);
          if (coordenadoria) {
            console.log(coordenadoria);
            return replace(`/dashboards/coordenadoria/${coordenadoria[0].sigla}`);
          }
          if (divisao) {
            console.log(divisao);
            return replace(`/dashboards/divisao/${divisao[0].identificadorOrganizacional}`);
          }

          // Lógica original para redirecionamento
          if (numberRole === 1 && !infoUserApi.usuario.flagAtivo) {
            return replace(`/dashboards/coordenadoria/ }`);
          }
          const serviceRoute = profilesServiceRoutes[numberRole];
          return replace(`/${serviceRoute}`);

          // Trech de código que redireciona para as telas de confirmação de perfil

          // const confirmProfile = !infoUserApi.flagRegistroCompleto
          //   ? '/confirmar-perfil'
          //   : ''
          // const serviceRoute = profilesServiceRoutes[numberRole]

          // return replace(`/${serviceRoute}${confirmProfile}`)
        } catch (error) {
          console.error('Erro ao processar solicitação à API:', error);
        }
      }
      return replace('/');
    };
    fetchAndVerifyApiAcessoCidadao();
  }, [handleLogout, replace, searchParams]);
}
export { AuthContextProvider, useAuthentication, useFetchAndVerifyAcessoCidadao };