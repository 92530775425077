import Image from 'next/image';
import { ContactsOutlined, MessageOutlined, PhoneOutlined } from '@ant-design/icons';
import * as S from './styles';
import imgLogoSecitecePng from '../../../public/png/logotipo-secitece.png';
export function Footer() {
  return <S.ContainerFooter data-sentry-element="unknown" data-sentry-component="Footer" data-sentry-source-file="index.tsx">
      <S.ColorStripe data-sentry-element="unknown" data-sentry-source-file="index.tsx">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </S.ColorStripe>
      <S.MainFooter data-sentry-element="unknown" data-sentry-source-file="index.tsx">
        <S.ContentFooter data-sentry-element="unknown" data-sentry-source-file="index.tsx">
          <S.WrapperImg data-sentry-element="unknown" data-sentry-source-file="index.tsx">
            <Image alt="Logotipo da SECITECE" src={imgLogoSecitecePng} sizes="100vw" style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain'
          }} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
          </S.WrapperImg>

          <S.WrapperInfo data-sentry-element="unknown" data-sentry-source-file="index.tsx">
            <div className="emphasis">
              <span>Sede da Secitece</span>
            </div>
            <div>
              Av. Dr. José Martins Rodrigues, 150 <br /> Edson Queiroz,
              Fortaleza - CE <br />
              CEP: 60.811-520
            </div>
            <div className="emphasis">
              <span>sct.ce.gov.br</span>
            </div>
          </S.WrapperInfo>

          <S.WrapperNav data-sentry-element="unknown" data-sentry-source-file="index.tsx">
            <ul>
              <li>
                <a href="https://cearatransparente.ce.gov.br/portal-da-transparencia/ouvidoria?locale=pt-BR" target="_blank" rel="noreferrer">
                  <div className="wrapper-icon-footer link">
                    <div className="icon-footer">
                      <MessageOutlined data-sentry-element="MessageOutlined" data-sentry-source-file="index.tsx" />
                    </div>
                    Ouvidoria
                  </div>
                </a>
              </li>

              <li>
                <a href="tel:+155" target="_blank" rel="noreferrer">
                  <div className="wrapper-icon-footer link">
                    <div className="icon-footer">
                      <PhoneOutlined data-sentry-element="PhoneOutlined" data-sentry-source-file="index.tsx" />
                    </div>
                    Central 155
                  </div>
                </a>
              </li>

              <li>
                <a href="#">
                  <div className="wrapper-icon-footer link">
                    <div className="icon-footer">
                      <ContactsOutlined data-sentry-element="ContactsOutlined" data-sentry-source-file="index.tsx" />
                    </div>
                    Horário de Atendimento <br />
                    08 às 17 horas
                  </div>
                </a>
              </li>
            </ul>
          </S.WrapperNav>
        </S.ContentFooter>
      </S.MainFooter>
      <S.BottomFooter data-sentry-element="unknown" data-sentry-source-file="index.tsx">
        <span>
          ©2024 Governo do Estado do Ceará - Todos os direitos reservados.
        </span>
      </S.BottomFooter>
    </S.ContainerFooter>;
}