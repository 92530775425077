'use client';

import { HeaderAcessibility } from '@/components/Headers/HeaderAcessibility';
import { Footer } from '@/components/Footer';
import * as S from './styles';
export function PublicLayout({
  children
}: {
  children: React.ReactNode;
}) {
  return <S.ContainerPublicLayout data-sentry-element="unknown" data-sentry-component="PublicLayout" data-sentry-source-file="index.tsx">
      <HeaderAcessibility data-sentry-element="HeaderAcessibility" data-sentry-source-file="index.tsx" />

      <S.ContentPublicLayout data-sentry-element="unknown" data-sentry-source-file="index.tsx">{children}</S.ContentPublicLayout>

      <Footer data-sentry-element="Footer" data-sentry-source-file="index.tsx" />
    </S.ContainerPublicLayout>;
}