'use client';

import cegov from 'cegov-antd';
const {
  Accessibility
} = cegov();
import { useAppAccessibility } from '@/context/accessibilityContext';
export const HeaderAcessibility = () => {
  const {
    addLengthText,
    removeLengthText,
    resetLengthText
  } = useAppAccessibility();
  return <>
      <Accessibility childrenCookieModal={<></>} okCookieModal={() => console.log('Clicou okCookieModal')} titleCookieModal={'Políticas de privacidade e termos de uso'} okRejectModal={() => console.log('Clicou okRejectModal')} normalFontSize={resetLengthText} maximunFontSize={addLengthText} minimumFontSize={removeLengthText} data-sentry-element="Accessibility" data-sentry-source-file="index.tsx" />
    </>;
};