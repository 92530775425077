import styled, { css } from 'styled-components'

interface StyledButtonProps {
  $size: 'small' | 'medium' | 'large' | 'none'
  $iconOnly: boolean
  $buttonType:
    | 'primary'
    | 'alternativePrimary'
    | 'secondary'
    | 'alternativeSecondary'
    | 'tertiary'
    | 'tertiarySecondary'
    | 'danger'
    | 'dangerSecondary'
  $active: boolean
  $loading: boolean
  $disable: boolean
}

const sizeVariants = {
  none: css``,
  small: css`
    padding: 8px 16px;
    font-size: ${({ theme }) => theme.font.sizes.small};
  `,
  medium: css`
    padding: 10px 24px;
    font-size: ${({ theme }) => theme.font.sizes.medium};
  `,
  large: css`
    padding: 12px 32px;
    font-size: ${({ theme }) => theme.font.sizes.large};
  `
}

const buttonTypeVariants = {
  primary: css`
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    border: 2px solid transparent;

    &:hover {
      border: 2px solid ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.primary};
      background-color: #dee2e6;
    }
    &:active {
      background-color: #fbfbfb;
      color: #1e862c;
      border: 2px solid transparent;
    }
  `,
  alternativePrimary: css`
    color: ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.primary};

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
      background-color: #dee2e6;
    }
    &:active {
      background-color: #fbfbfb;
      color: #1e862c;
      border: 2px solid transparent;
    }
  `,
  secondary: css`
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
    border: 2px solid transparent;

    &:hover {
      background-color: #dee2e6;
      color: ${({ theme }) => theme.colors.secondary};
      border: 2px solid ${({ theme }) => theme.colors.secondary};
      .icon-btn.only-icon {
        color: ${({ theme }) => theme.colors.secondary};
      }
    }
    &:active {
      background-color: #fbfbfb;
      color: #33627d;
      border: 2px solid transparent;
    }
  `,
  alternativeSecondary: css`
    color: ${({ theme }) => theme.colors.secondary};
    background-color: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.secondary};

    &:hover {
      color: ${({ theme }) => theme.colors.secondary};
      background-color: #dee2e6;
    }
    &:active {
      background-color: #fbfbfb;
      color: ${({ theme }) => theme.colors.secondary};
      border: 2px solid transparent;
    }
  `,
  tertiary: css`
    background-color: ${({ theme }) => theme.colors.tertiary};
    color: ${({ theme }) => theme.colors.white};
    border: 2px solid transparent;

    &:hover {
      background-color: #dee2e6;
      color: ${({ theme }) => theme.colors.tertiary};
      border: 2px solid ${({ theme }) => theme.colors.tertiary};
    }
    &:active {
      background-color: #fbfbfb;
      border: 2px solid transparent;
    }
  `,
  tertiarySecondary: css`
    background-color: #dee2e6;
    color: ${({ theme }) => theme.colors.tertiary};
    border: 2px solid ${({ theme }) => theme.colors.tertiary};

    &:hover {
      background-color: ${({ theme }) => theme.colors.tertiary};
      color: ${({ theme }) => theme.colors.white};
      border: 2px solid transparent;
    }
    &:active {
      background-color: #fbfbfb;
      border: 2px solid transparent;
    }
  `,

  danger: css`
    background-color: ${({ theme }) => theme.colors.danger};
    color: ${({ theme }) => theme.colors.white};
    border: 2px solid transparent;

    &:hover {
      background-color: ${({ theme }) => theme.colors.dangerHover};
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.dangerActive};
    }
  `,
  dangerSecondary: css`
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.danger};
    border: 2px solid ${({ theme }) => theme.colors.danger};

    &:hover {
      background-color: ${({ theme }) => theme.colors.dangerHover};
      color: ${({ theme }) => theme.colors.white};
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.dangerActive};
    }
  `
}

export const StyledButton = styled.button<StyledButtonProps>`
  position: relative;
  cursor: pointer;
  user-select: none;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 4px;

  font-family: ${({ theme }) => theme.font.secondary};
  font-weight: ${({ theme }) => theme.font.extraBold};
  font-size: ${({ theme }) => theme.font.sizes.xlarge};

  transition: background-color 200ms ease-in-out;

  ${({ $size }) => sizeVariants[$size]};
  ${({ $buttonType }) => buttonTypeVariants[$buttonType]};

  ${({ $iconOnly, theme }) =>
    $iconOnly &&
    css`
      width: 45px;
      height: 45px;
      .icon-btn {
        font-size: ${theme.font.sizes.xlarge};
      }
    `}

  &.rounded {
    border-radius: 40px;
  }

  ${({ theme, $active, $buttonType }) =>
    $active &&
    css`
      pointer-events: none;
      background-color: ${theme.colors.attention};
      color: ${$buttonType === 'tertiary'
        ? theme.colors.attention
        : theme.colors.black};
    `}

  ${({ $loading }) =>
    $loading &&
    css`
      pointer-events: none;
      opacity: 0.9;
    `}

  ${({ $disable }) =>
    $disable &&
    css`
      pointer-events: none;
      background-color: #f8f9fa;
      color: #91a0ab;
    `};
`

export const Icon = styled.span<{
  $iconOnly: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 13px;
  ${({ $iconOnly }) =>
    !$iconOnly &&
    css`
      margin-right: 8px;
    `}
`
export const iconAfterText = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 20px;
  margin-left: 10px;
`
