'use client';

import { createGlobalStyle, css } from 'styled-components';
import { resetCss } from './reset-css';
interface GlobalStyleProps {
  lengthText: number;
}
export const GlobalStyles = createGlobalStyle<GlobalStyleProps>`
  ${resetCss}
  
  body {
    background-color: ${({
  theme
}) => theme.colors.bg};
  }

  body,
  input,
  button {
    font-family: ${({
  theme
}) => theme.font.primary};

    font-weight: ${({
  theme
}) => theme.font.normal};
    font-size: ${({
  theme
}) => theme.font.sizes.medium};
    border: none;
    font-variant-numeric: lining-nums;
  }

  * {
    margin: 0;
    padding: 0;
    outline: none; 
    box-sizing: border-box;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  html {
    font-size: ${props => props.lengthText === 0 ? '62.5%' : `${62.5 + props.lengthText * 10}%`};
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 0;
    margin: 0;

    font-family: ${({
  theme
}) => theme.font.primary};
    font-variant-numeric: lining-nums;
  }
  
  .body-scroll-lock {
    overflow: hidden;
  }
  
  ${({
  theme,
  lengthText
}) => css`
    ${theme.breakpoints.lg} {
      html {
        font-size: ${lengthText === 0 ? '50%' : `${50 + lengthText * 7}%`};
      }
    }
  `}

  .ant-tabs-nav-operations,
  .ant-tabs-dropdown {
    display: none !important;
  }
  .ant-typography-expand{
    color: #081C15 !important;
    background-color:  #D9D9D9  !important;
    border-radius: 3px !important;
    padding: 1px !important;
    font-weight: ${({
  theme
}) => theme.font.extraBold}  !important;
  }
  
  .cegov-container-acessibility-bar .acessibility-container{
    max-width: 1140px !important;

  .active{
    top: 30px !important;
  }

  #v-libras{
    left: initial;
    right: 14px;
    top: 85%;
    bottom: initial;
    transform: translateY(calc(-50% - 10px));

  }
}
`;