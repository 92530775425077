import { PieChartOutlined, RocketOutlined, EditOutlined } from '@ant-design/icons';
import { PublicHeader } from '@/components/Headers/PublicHeader';
import * as S from './styles';
export function LandingPage() {
  return <S.ContainerLandingPage data-sentry-element="unknown" data-sentry-component="LandingPage" data-sentry-source-file="index.tsx">
      <PublicHeader data-sentry-element="PublicHeader" data-sentry-source-file="index.tsx" />

      <S.SectionInfo data-sentry-element="unknown" data-sentry-source-file="index.tsx">
        <div className="content">
          <S.BgLandingPage data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
          <div>
            <span className="title">
              Plataforma de Indicadores <br />
              da SECITECE
            </span>
            <span className="description">
              Ferramenta para análise de dados e monitoramento de indicadores
              estratégicos dos Programas da Secretaria da Ciência, Tecnologia e
              Educação Superior.
            </span>
          </div>
        </div>
      </S.SectionInfo>

      <div className="content">
        <S.SectionListServices data-sentry-element="unknown" data-sentry-source-file="index.tsx">
          <S.Card data-sentry-element="unknown" data-sentry-source-file="index.tsx">
            <PieChartOutlined data-sentry-element="PieChartOutlined" data-sentry-source-file="index.tsx" />
            <S.TitleCard data-sentry-element="unknown" data-sentry-source-file="index.tsx">
              <span>Indicadores</span>
            </S.TitleCard>
            <span>
              Painéis visuais de monitoramento de dados com informações
              detalhadas sobre os indicadores da SECITECE.
            </span>
          </S.Card>

          <S.Card data-sentry-element="unknown" data-sentry-source-file="index.tsx">
            <RocketOutlined data-sentry-element="RocketOutlined" data-sentry-source-file="index.tsx" />
            <S.TitleCard data-sentry-element="unknown" data-sentry-source-file="index.tsx">
              <span>Vitrine</span>
            </S.TitleCard>
            <span>
              Conheça as iniciativas inovadoras promovidas pela SECITECE, que
              incluem cursos, startups aceleradas e editais com chamadas
              abertas.
            </span>
          </S.Card>

          <S.Card data-sentry-element="unknown" data-sentry-source-file="index.tsx">
            <EditOutlined data-sentry-element="EditOutlined" data-sentry-source-file="index.tsx" />
            <S.TitleCard data-sentry-element="unknown" data-sentry-source-file="index.tsx">
              <span>Inscrições</span>
            </S.TitleCard>
            <span>
              Participe dos eventos, cursos e programas promovidos pela
              SECITECE, voltados ao desenvolvimento da ciência, tecnologia e
              inovação
            </span>
          </S.Card>
        </S.SectionListServices>
      </div>
    </S.ContainerLandingPage>;
}