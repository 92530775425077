import React, { useEffect, useState } from 'react';
import { Dropdown, Tooltip } from 'antd';
import { MenuProps } from 'antd/lib';
import * as S from './styles';
interface IProps {
  children: React.ReactNode;
  items: MenuProps['items'];
  trigger?: ('contextMenu' | 'click' | 'hover')[] | undefined;
  titleTooltip?: string;
  modalDarkBackground?: boolean;
  onChangeDrop?: React.Dispatch<React.SetStateAction<boolean>>;
  placement?: 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' | 'bottomRight' | 'top' | 'bottom' | undefined;
}
export function DropdownUI({
  children,
  items,
  trigger = ['hover'],
  titleTooltip,
  placement,
  onChangeDrop
}: IProps) {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const handleVisibleChange = (open: boolean) => {
    if (trigger?.includes('hover') && !trigger?.includes('click')) {
      setDropdownVisible(open);
      if (onChangeDrop) onChangeDrop(open);
      return;
    }
    if (trigger?.includes('click') && open === false) {
      setDropdownVisible(open);
      if (onChangeDrop) onChangeDrop(open);
      return;
    }
  };
  const handleClickOpen = () => {
    if (trigger?.includes('click')) {
      setDropdownVisible(old => !old);
      if (onChangeDrop) onChangeDrop(old => !old);
    }
  };
  useEffect(() => {
    const fecharModalSeNecessario = () => {
      if (window.innerWidth >= 600 && dropdownVisible) {
        setDropdownVisible(false);
        if (onChangeDrop) onChangeDrop(false);
      }
    };
    window.addEventListener('resize', fecharModalSeNecessario);
    return () => {
      window.removeEventListener('resize', fecharModalSeNecessario);
    };
  }, [dropdownVisible, onChangeDrop]);
  return <S.ContainerDropdownUI className={dropdownVisible ? 'actived' : ''} data-sentry-element="unknown" data-sentry-component="DropdownUI" data-sentry-source-file="index.tsx">
      <Dropdown menu={{
      items
    }} destroyPopupOnHide trigger={trigger} open={dropdownVisible} onOpenChange={handleVisibleChange}
    // placement="bottomRight"
    dropdownRender={menus => <S.ContentDropdownUI>{menus}</S.ContentDropdownUI>} arrow placement={placement} data-sentry-element="Dropdown" data-sentry-source-file="index.tsx">
        <div>
          {dropdownVisible ? <div onClick={handleClickOpen}>{children}</div> : <Tooltip title={dropdownVisible ? '' : titleTooltip} mouseLeaveDelay={0} placement="bottom">
              <div onClick={handleClickOpen}>{children}</div>
            </Tooltip>}
        </div>
      </Dropdown>
    </S.ContainerDropdownUI>;
}