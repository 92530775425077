export const profilesServiceRoutes = [
  'dashboards/admin-geral',
  'dashboards/coordenadoria',
  'dashboards/divisao',
  'dasboards/instrutor',
  'dashboards/colaborador',
  'dashboards/empresa',
  'dashboards/supervisor',
  'dashboards/cidadao'
]

export type TypeRolesPermisson =
  | 'ROLE_ADMINISTRADOR_GERAL'
  | 'ROLE_ADMINISTRADOR_COORDENADORIA'
  | 'ROLE_ADMINISTRADOR_DIVISAO'
  | 'ROLE_INSTRUTOR_UTD'
  | 'ROLE_COLABORADOR'
  | 'ROLE_EMPRESA'
  | 'ROLE_CIDADAO'
  | 'ROLE_SUPERVISOR'

export type TypeDivisions =
  | 'CELULA'
  | 'PROGRAMA'
  | 'PROJETO'
  | 'NUCLEO'
  | 'EVENTO'

export const profilesPermisson = {
  ROLE_ADMINISTRADOR_GERAL: 0,
  ROLE_ADMINISTRADOR_COORDENADORIA: 1,
  ROLE_ADMINISTRADOR_DIVISAO: 2,
  ROLE_INSTRUTOR_UTD: 3,
  ROLE_COLABORADOR: 4,
  ROLE_EMPRESA: 5,
  ROLE_CIDADAO: 6,
  ROLE_SUPERVISOR: 7
}

export const profilesLabels: {
  [key in TypeRolesPermisson]: string
} = {
  ROLE_ADMINISTRADOR_GERAL: 'Administrador Geral',
  ROLE_ADMINISTRADOR_COORDENADORIA: 'Administrador Coordenadoria',
  ROLE_ADMINISTRADOR_DIVISAO: 'Administrador Divisão',
  ROLE_INSTRUTOR_UTD: 'Instrutor da UTD',
  ROLE_COLABORADOR: 'Colaborador',
  ROLE_EMPRESA: 'Empresa',
  ROLE_CIDADAO: 'Cidadão',
  ROLE_SUPERVISOR: 'Supervisor'
}

export const KEY_STORAGE_USER = 'userInfoPlataforma'
export const KEY_STORAGE_COORDENADORIA = 'coordenadoriaInfoPlataforma'
export const KEY_STORAGE_ADMIN = 'adminInfoPlataforma'
export const KEY_STORAGE_FIRST_NAME = 'firstNameInfoPlataforma'
export const KEY_STORAGE_DIVISOES = 'divisoesInfoPlataforma'
export const KEY_STORAGE_INSTRUTOR_UTD = 'instrutorUTD'

export const KEY_CACHE_TOKEN = 'authTokenPlataforma'
export const KEY_CACHE_PROFILE_USER = 'profileUserPlataforma'
