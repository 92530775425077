import styled from 'styled-components'

export const ContainerDropdownUI = styled.div`
  && {
    .btn-dropdown-actived {
      background-color: ${({ theme }) => theme.colors.danger};

      color: ${({ theme }) => theme.colors.white};

      &:hover {
        background-color: ${({ theme }) => theme.colors.secondary};
      }
    }
    &.actived {
      .btn-dropdown-actived {
        background-color: ${({ theme }) => theme.colors.su};
        color: ${({ theme }) => theme.colors.black};
      }
    }
  }
`

export const ContentDropdownUI = styled.div<{ hasScroll: boolean }>`
  && {
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.4);
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 8px;

    ul.ant-dropdown-menu {
      padding: 0px;
      margin: 0px;

      background-color: transparent;

      gap: 10px;
      > li.ant-dropdown-menu-item {
        background-color: transparent;
        padding: 0px;
        margin: 0px;
      }
      transition: all 250ms ease-in-out;

      > li.ant-dropdown-menu-item-active {
        padding: 0px;
        margin: 0px;
        background-color: transparent;
      }
    }
  }
  max-height: ${({ hasScroll }) => (hasScroll ? '200px' : 'auto')};
  overflow-y: ${({ hasScroll }) => (hasScroll ? 'auto' : 'visible')};
`
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  z-index: ${({ theme }) => theme.zIndex.dropdown};
  background-color: rgba(0, 0, 0, 0.4);
`
